
.bg-purple {
    background-color: #6f42c1;
}
.bg-beeline {
    background-color: rgb(255,250,238)
}

body {
    background-color: rgb(255,250,238)
}

.login {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -150px 0 0 -150px;
    width:300px;
    height:300px;
}

